<template>
  <div v-click-away="closeFolderMenu" style="inset: 36px auto auto auto;width: 232px;z-index:300;position:absolute;">

    <div v-if="loaded" style="background:#fff;border-radius: 4px;box-shadow: 0 15px 35px 0 rgba(60,66,87,.08),0 5px 15px 0 rgba(0,0,0,.12);">

      <div style="padding:12px 0;box-shadow: inset 0 -1px #e3e8ee;">
        <div @click.prevent="toggleCreateFolder" style="cursor:pointer;font-size:16px;padding: 4px 20px;font-weight:bold;color:#3c4257;">Create folder</div>
      </div>

      <div style="padding:8px 0;display:flex;flex-direction:column;">
        <div v-for="folder in folders" :key="folder._id">
          <router-link :to="{ name: 'Folder', params: { id: folder._id } }" @click.native="closeFolderMenu">
            <div style="font-size:14px;padding: 6px 20px;cursor:pointer;">{{ folder.name }}</div>
          </router-link>
        </div>
      </div>
    </div>
      
  </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  data(){
    return {
      loaded: false
    }
  },
  computed: {
      ...mapState ({
        folders: state => state.folders.items
     }),
   },
  created(){
    this.$store.dispatch('folders/fetchFolders')
    .then(() => {
      this.loaded = true
    })
  },
  methods: {
    toggleFolderMenu(){
      this.$emit('toggleFolderMenu')
    },
     toggleCreateFolder(){
      this.$emit('toggleCreateFolder')
    },
    closeFolderMenu(){
        this.$emit('closeFolderMenu')
    }
  }
}
</script>

<style scoped>
</style>
