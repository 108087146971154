<template>
<div style="flex: 1 1 800px;display:flex;padding:120px 40px 60px 40px;">


  <div style="font-size:16px;font-weight:600;margin-bottom: 20px;text-transform:uppercase">Settings</div>



</div>
</template>

<script>
export default {

}
</script>

<style scoped>
</style>
