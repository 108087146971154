<template>
  <div style="flex: 1 1 800px;display:flex;align-items: flex-start;">
    <div style="padding: 120px 40px 60px 40px;max-width: 740px;min-width: 740px;">
        <div style="display:flex;justify-content:space-between;margin-bottom:20px;align-items:center;">
          <div style="text-transform:uppercase;font-size:16px;font-weight:600;">Dashboard</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  created(){
    document.title = 'Side CDN'
  },
}
</script>

<style scoped>

</style>
