<template>
  <main style="display:flex;align-items:center;justify-content:center;height:100vh;width:100vw;background:#f7fafc;">

    <div style="display:flex;flex-direction:column;width: 540px;min-width: 540px;">
      <div style="margin-bottom:30px;font-size:20px;font-weight:bold;">Side</div>

      <div style="box-shadow: 0 15px 35px 0 rgba(60,66,87,.08),0 5px 15px 0 rgba(0,0,0,.12);border-radius:4px;display:flex;flex-direction:column;padding:56px 48px;justify-content:center;background:#fff;">



        <div style="display:flex;flex-direction:column;">
          <label style="padding-bottom:8px;color:#3c4257;font-size:14px;font-weight:500;">Email</label>
          <input v-model="form.email" @keyup.enter.exact="login" @keydown.enter.exact.prevent type="email" placeholder="" style="max-width: 100%;background-color:#f7fafc;border:none;height:40px;padding:10px 16px;border-radius:4px;font-size:14px;" />
        </div>

        <div style="display:flex;flex-direction:column;margin-top:30px;">
          <div style="padding-bottom:8px;display:flex;justify-content:space-between;align-items:center;">
            <label style="color:#3c4257;font-size:14px;font-weight:500;">Password</label>
            <!-- <div style="font-size: 14px;color:#1a1f36;cursor:pointer;">Forgot password?</div> -->
          </div>
          <input v-model="form.password" @keyup.enter.exact="login" @keydown.enter.exact.prevent type="password" placeholder="" style="max-width: 100%;background-color:#f7fafc;border:none;height:40px;padding:10px 16px;border-radius:4px;font-size:14px;" />
        </div>

        <div style="display:flex;margin-top:60px;">
          <div @click.prevent="login" class="button" style="border: none;width:100%;height:40px;font-weight:600;color:#4f566b;display:flex;align-items:center;justify-content:center;cursor:pointer;border-radius:4px;font-size: 14px;background-color:rgb(196, 252, 228);padding: 3px 8px;">
            <div>Login</div>
          </div>
        </div>

      </div>

      <div style="margin-top:30px;font-size: 14px;color:#1a1f36;line-height:20px;">This is a production system owned by Side Limited, unauthorized use of this system is punishable by NZ Law, all activity is monitored.</div>

    </div>

  </main>
</template>

<script>
export default {
  data() {
    return {
      loginClicked: false,
      form: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    login () {
      if(this.loginClicked) {
        console.log('user spamming')
      } else {
        this.loginClicked = true
        this.$store.dispatch('auth/loginWithEmailAndPassword', this.form)
          .then(() => {
            window.location.href = "/"
            this.loginClicked = false
            })
          .catch((err) => {
            this.$toast(err)
            this.loginClicked = false
          })
      }
    },
  }
}
</script>

<style scoped>

.button {
  border:2px solid #e8e8e8;
  transition: .3s ease-in-out;
}

.button:hover {
  /* border-color: #2e3338; */
  /* border-width: 5px; */
}
</style>
