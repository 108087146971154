import axios from 'axios'
import jwt from 'jsonwebtoken'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

const BASE_URL = process.env.VUE_APP_URL

//jwt
function checkTokenValidity (token) {
  if (token) {
    const decodedToken = jwt.decode(token)
    return decodedToken && (decodedToken.exp * 1000) > new Date().getTime()
  }
  return false
}
//jwt end

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {
    authUser (state) {
      return state.user || null
    },
    isAuthenticated (state) {
      return !!state.user
    },
  },
  actions: {
    loginWithEmailAndPassword ({commit}, userData) {
      return axios.post(`${BASE_URL}/users/login`, userData)
        .then(res => {
          const user = res.data
          localStorage.setItem('cdn-jwt', user.token)
          commit('setAuthUser', user)
        })
        .catch(err => rejectError(err))
    },

    updateAuthUser({commit}, updatedUser) {
      commit('setAuthUser', updatedUser)
      return updatedUser
    },
    registerUser (context, userData){
      return axios.post(`${BASE_URL}/users/register`, userData)
        .catch(err => rejectError(err))
    },
    getAuthUser ({commit, getters}) {
      const authUser = getters['authUser']
      const token = localStorage.getItem('cdn-jwt')

      if (!token) {
        commit('setAuthUser', null)
        commit('setAuthState', true)
        return false
      }
      const isTokenValid = checkTokenValidity(token)

      if (authUser && isTokenValid) { return Promise.resolve(authUser) }

      const config = {
        headers: {
          'Cache-Control': 'no-cache'
        }
      }

      return axiosInstance.get(`${BASE_URL}/users/me`, config)
        .then((res) => {
          const user = res.data
          localStorage.setItem('cdn-jwt', user.token)
          commit('setAuthUser', user)
          commit('setAuthState', true)
          return user
        })
        .catch(err => {
          commit('setAuthUser', null)
          commit('setAuthState', true)
        })
    },
    logout ({commit}) {
      return new Promise((resolve) => {
        localStorage.removeItem('cdn-jwt')
        commit('setAuthUser', null)
        resolve(true)
      })
    },



  },
  mutations: {
    setAuthUser(state, user) {
      return state.user = user
    },
    setAuthState(state, authState) {
        return state.isAuthResolved = authState
    },
    updateUserState(state, data) {
      state.user.email = data.email
      state.user.name = data.name
    }
    }
  }
