<template>
<div style="background-color:rgba(0, 0, 0, 0.8);position:fixed;top:0;right:0;bottom:0;left:0;z-index:500;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
    <div v-click-away="toggleCreateFolder" style="padding:20px;border-radius:5px;width:440px;min-height:258px;background:#fff;display:flex;flex-direction:column;justify-content:center;">

      <div style="margin-bottom:20px;">
        <div style="font-weight:600;text-transform:uppercase;font-size:16px;">Add Folder</div>
      </div>

        <div style="display:flex;flex-direction:column;">
          <div style="margin-bottom:8px;font-size:12px;font-weight:500;text-transform:uppercase;">Folder Name</div>
            <input v-model="form.name" style="font-weight:500;padding:10px;font-size: 16px;background-color:#f2f3f5;width:100%;border:none;" type="text" />
        </div>

      <div style="padding:40px 0 0 0;display:flex;justify-content:flex-end;">
        <div @click.prevent="toggleCreateFolder" style="color:#747f8d;cursor:pointer;font-size:14px;margin-right:20px;background-color:#fff;display:flex;justify-content:center;align-items:center;font-weight:500;padding:0 15px;">
          <div>Cancel</div>
        </div>

        <div @click.prevent="createFolder" style="cursor:pointer;font-size:14px;color: rgb(6, 6, 7);background-color:#f2f3f5;height:38px;display:flex;justify-content:center;padding:0 30px;font-weight:500;align-items:center;border-radius:3px;">
          <div>Create Folder</div>
        </div>

      </div>

    </div>

</div>
</template>

<script>
export default {
  data(){
    return {
      createClicked: false,
      form: {
        name: null
      }
    }
  },
  methods: {
    toggleCreateFolder(){
      this.$emit('toggleCreateFolder')
    },
    createFolder(){
      if(this.createClicked){
        console.log('user spamming')
      } else {
        this.createClicked = true
        this.$store.dispatch('folders/createFolder', this.form)
        .then((res) => {
          this.$router.push({ path: `/folder/${res}` })
            this.toggleCreateFolder()
            this.createClicked = false
        })
        .catch((err) => {
          this.$toast(err)
          this.createClicked = false
        })
      }
    }
  }
}
</script>
