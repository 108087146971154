import Toast from '../components/Toast.vue';
import { createApp } from 'vue';

const Toasted = {
    install(app) {

      app.config.globalProperties.$toast = function (message) {
     
        const el = document.createElement('div')
        document.body.appendChild(el);
        const ComponentApp = createApp(Toast, { message,
            onClose: () => {
                document.body.removeChild(el)
                ComponentApp.unmount(el)
            } 
        })
        
        ComponentApp.mount(el);

      };
    },
  };
  
  export default Toasted;


