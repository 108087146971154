<template>
  <div class='vue-full-screen-file-drop' :class='classes' >
  <div v-if="visible" class="uploadArea-3QgLtW uploadModalIn-1z07Bv">
    <div class="uploadDropModal-2kTwbc">
        <div class="bgScale-1otPtc"></div>
        <div class="inner-3nWsbo">

            <div class="title-2Vtl4y">Upload File</div>

        </div>
    </div>
</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      lastTarget: null,
    };
  },
  computed: {
      classes() {
        return {
          'vue-full-screen-file-drop--visible': this.visible,
        };
      },
    },
    methods: {
    onDragEnter(e) {
      this.lastTarget = e.target;
      this.visible = true;
    },
    onDragLeave(e) {
      if (e.target === this.lastTarget) {
        this.visible = false;
      }
    },
    onDragOver(e) {
      e.preventDefault();
    },
    onDrop(e) {
      e.preventDefault();
      this.visible = false;
      const files = e.dataTransfer.files;
      console.log(files)
    },

},
  mounted() {
    window.addEventListener('dragenter', this.onDragEnter);
    window.addEventListener('dragleave', this.onDragLeave);
    window.addEventListener('dragover', this.onDragOver);
    window.addEventListener('drop', this.onDrop);
  },
  beforeDestroy() {
    window.removeEventListener('dragenter', this.onDragEnter);
    window.removeEventListener('dragleave', this.onDragLeave);
    window.removeEventListener('dragover', this.onDragOver);
    window.removeEventListener('drop', this.onDrop);
  },
}
</script>

<style scoped>
.vue-full-screen-file-drop {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 10000;
   width: 100%;
   height: 100%;
   background-color: rgba(0,0,0,0.4);
   visibility: hidden;
   opacity: 0;
   transition: visibility 200ms, opacity 200ms;
 }
 .vue-full-screen-file-drop--visible {
   opacity: 1;
   visibility: visible;
 }
</style>
