<!-- Toast.vue -->
<template>
    <div v-if="show" id="pop-up" class="toast-container">
        <div class="toast">
            {{ message }}
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      message: String,
    },
    data(){
        return {
            show: true
        }
    }, 
    mounted() {
    setTimeout(() => {
      this.show = false;
      this.$emit('close');
    }, 5000);
  },

  };
  </script>
  
  <style scoped>
  .toast {
    
    font-size: 15px;
    font-weight: 600;
    color:#fff;
    padding: 12px 15px;
  }
.toast-container {
    display:flex;
    border-radius: 6px;
    background:#202d4a;
    position: fixed;
    z-index: 10000;
    width: auto;
    -webkit-box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
    box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
  }

#pop-up {
  left:25px;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  animation: pop 0.5s ease forwards;
}

@keyframes pop {
  0%{}
  100%{ bottom: 40px}
}

</style>
  