import Vue from 'vue';
import { createStore } from "vuex"

import auth from './modules/auth'
import folders from './modules/folders'
import files from './modules/files'



export default new createStore({
  modules: {
    auth,
    folders,
    files
  },
  mutations: {
    setItems (state, { resource, items}) {
      state[resource].items = items
    },
    setItem (state, { resource, item}) {
      state[resource].item = item
    },
    addItemToArray (state, {item, index, resource}) {
      Vue.set(state[resource].items, index, item)
    }
  }
})
