const ClipboardDirective = {
    beforeMount(el, binding) {
      el.addEventListener('click', () => {
        const textToCopy = binding.value;
        navigator.clipboard.writeText(textToCopy)
        .then(() => {
          el.dispatchEvent(new Event('copied'));
        })
        .catch((err) => {
          console.error('Unable to copy text to clipboard', err);
        });
      });
    },
  };
  
  const ClipboardPlugin = {
    install(app) {
      app.directive('clipboard', ClipboardDirective);
    },
  };
  
  export default ClipboardPlugin;