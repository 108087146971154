<template>
<div v-click-away="toggleOptions" class="menu" :style="{ top: data.y + 'px', left: data.x + 'px'}" >
  <div v-if="data.type === 'file'">
    <div @click.prevent="copyLink" class="menu-item">Copy Link</div>
    <div class="separator"></div>
    <div @click.prevent="deleteFile" class="menu-item danger">Delete</div>
  </div>

  <div v-if="data.type === 'folder'">
    <div @click.prevent="rename" class="menu-item">Rename</div>
    <div class="separator"></div>
    <div @click.prevent="deleteFolder" class="menu-item danger">Delete</div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      deleteClicked: false
    }
  },
  methods: {
    toggleOptions(){
      this.$emit('toggleOptions')
    },
    deleteFile(){
      this.toggleOptions()
      this.$emit('toggleDelete', { type: 'file', data: this.data.file })
    },
    deleteFolder(){
      this.toggleOptions()
      this.$emit('toggleDelete', { type: 'folder', data: this.data.file })
    },
    rename(){
      this.$emit('toggleRename', this.data.file)
    },
    copyLink(){
      this.$copyText(this.data.file.url)
      .then(() => {
        this.toggleOptions()
        this.$toast('Copied')
      })

    }
  }
}
</script>

<style scoped>

.menu {
  position:absolute;
  min-width: 188px;
  max-width: 320px;
  box-shadow:0 8px 16px rgba(0, 0, 0, 0.16);
  background-color:#fff;
  border-radius: 4px;
  display:flex;
  flex-direction:column;
}

.separator {
  border-bottom: 1px solid rgba(6, 6, 7, 0.08);
}

.menu-item {
  color:#4f5660;
  padding: 10px 8px;
  font-size:14px;
  cursor:pointer;
  font-weight:500;
}

.menu-item:hover {
  background-color: #f2f3f5;
}

.menu-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px
}

.menu-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

.danger {
  color:#FF416C;
}
</style>
