<template>
  <div id="app">
  <div v-if="this.$route.name === 'Register' || this.$route.name === 'Login' || this.$route.name === 'NotFound'">
    <router-view :key="$route.path" />
  </div>

  <div v-if="this.$route.name === 'Dashboard' || this.$route.name === 'Folder'">
   
      <Sidebar @toggleUpload="toggleUpload"/>
      <router-view :key="$route.path" @toggleDelete="toggleDelete" @toggleOptions="toggleOptions" />
      <!-- <DragDrop /> -->
      <Upload v-if="uploadOpen" :data="uploadData" @closeUpload="closeUpload"/>
      <Rename v-if="renameOpen" @toggleRename="toggleRename" :folder="renameData" />
      <ImagePreview v-if="imagePreviewOpen" />
      
      <FileOptions v-if="optionsOpen" @toggleRename="toggleRename" @toggleOptions="toggleOptions" @toggleDelete="toggleDelete" :data="fileData" />
      <Delete v-if="deleteOpen" @toggleDelete="toggleDelete" :data="deleteData" />
    
  </div>
</div>
</template>

<script>
import DragDrop from './components/DragDrop.vue'
import Upload from '@/components/Upload.vue'
import ImagePreview from '@/components/ImagePreview.vue'
import FileOptions from '@/components/FileOptions.vue'
import Sidebar from '@/components/Sidebar.vue'
import Delete from '@/components/Delete.vue'
import Rename from '@/components/Rename.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    DragDrop,
    Upload,
    ImagePreview,
    FileOptions,
    Sidebar,
    Delete,
    Rename
  },
  data(){
    return {
      uploadOpen: false,
      imagePreviewOpen: false,
      optionsOpen: false,
      fileData: {},
      deleteOpen: false,
      deleteData: {},
      renameOpen: false,
      renameData: {},
      uploadData: {}
    }
  },
  computed: {
    ...mapGetters({
      'user': 'auth/authUser'
    }),
  },
  created(){
    this.$store.dispatch('auth/getAuthUser')
    .then(()  => {
      this.loaded = true
    })
  },
  methods: {
    toggleRename(data){
      this.renameData = data
      this.renameOpen = !this.renameOpen
    },
    toggleDelete(data){
      this.deleteData = data
      this.deleteOpen = !this.deleteOpen
    },
    toggleOptions(data){
      this.fileData = data
      this.optionsOpen = !this.optionsOpen
    },
    toggleUpload(data){
      this.uploadOpen = !this.uploadOpen
      this.uploadData = data
    },
    closeUpload(){
      this.uploadOpen = false
      this.uploadData = {}
    },
    toggleImagePreview(){
      this.imagePreviewOpen = !this.imagePreviewOpen
    },
  }
}
</script>

<style>

* {
  outline: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

.toasted .primary.error, .toasted.toasted-primary.error {
    background:#202124!important;
    font-size: 13px;
    font-weight: bold;
    border-radius: 6px;
    color:#fff;
    height: 40px;
    -webkit-box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
    box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
    z-index: 9997;
}

@media only screen and (max-width: 600px) {
  .toasted .primary.error, .toasted.toasted-primary.error {
    border-radius: 0;
  }
}
</style>
