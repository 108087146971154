import Vue from 'vue'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

const BASE_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {
    items: [],
    item: {}
  },
  actions: {
    fetchFiles ({ state, commit }) {
      commit('setItems', {resource: 'files', items: []}, {root: true}) //reset state

    return axiosInstance.get(`${BASE_URL}/files`)
      .then(res => {
        const files = res.data
        commit('setItems', {resource: 'files', items: files}, {root: true})
        return state.items
      })
    },



  },
  mutations: {

  }
}
