<template>

<div style="background-color:rgba(0, 0, 0, 0.8);position:fixed;top:0;right:0;bottom:0;left:0;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">

  <!-- select image -->
  <!-- <div v-if="!addDetails" v-click-away="toggleUpload" style="background-color:#fff;border-radius:10px;width:300px;min-height:271px;display:flex;flex-direction:column;justify-content:center;">

    <div style="padding-top:36px;flex:1;display:flex;justify-content:center;">
      <div style="height:120px;width:180px;border-radius:10px;background-color: rgb(242, 243, 245);">
        <div style="display: flex;justify-content: center;align-items: center;height: 83%;width: 89%;border: 2px dashed rgba(6, 6, 7, 0.2);border-radius: 6px;margin: 10px;">
          <div style="font-weight: 600;color: rgb(6, 6, 7);">Drag & Drop</div>
        </div>
      </div>

    </div>

    <div style="padding:20px;display:flex;justify-content:center;">
      <div @click.prevent="toggleUpload" style="color:#747f8d;cursor:pointer;margin-right:20px;background-color:#fff;display:flex;justify-content:center;align-items:center;font-weight:500;padding:0 15px;">
        <div>Cancel</div>
      </div>

      <div style="position:relative;cursor:pointer;color: rgb(6, 6, 7);background-color:#f2f3f5;height:38px;display:flex;justify-content:center;padding:0 15px;font-weight:500;align-items:center;border-radius:3px;">
        <div>Select File</div>
        <input id="file" ref="file" @change="uploadFile($event)" style="position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer;" type="file" tabindex="0" multiple="" accept="">
      </div>

    </div>
  </div> -->
  <!-- select image end-->

  <div v-click-away="closeUpload" style="background-color:#fff;padding:20px;border-radius:10px;width:530px;min-height:258px;display:flex;flex-direction:column;justify-content:center;">


    <div style="margin-bottom:20px;">
      <div style="display:flex;">
        <div v-if="form.type.includes('audio')" style="height:104px;width:104px;position:relative;">
             <svg style="position:absolute;width:100%;left:0;top: 50%;transform: translateY(-50%);" height="40" width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                 <path fill="#f2f3f5" d="M501.969,5.638c-5.046-4.379-11.749-6.349-18.366-5.407L160.83,46.341c-11.359,1.623-19.795,11.351-19.795,22.825v92.221
                   v178.088c-13.656-7.956-29.509-12.537-46.421-12.537c-51.021,0-92.531,41.51-92.531,92.531S43.592,512,94.613,512
                   s92.531-41.51,92.531-92.531V181.383l276.663-39.523v151.504c-13.656-7.956-29.509-12.537-46.421-12.537
                   c-51.021,0-92.531,41.51-92.531,92.531c0,51.021,41.51,92.531,92.531,92.531s92.531-41.51,92.531-92.531V115.276V23.055
                   C509.918,16.371,507.018,10.017,501.969,5.638z"/>
             </svg>
        </div>
        <img v-else style="box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);height:104px;width:104px;object-fit:cover;border-radius:8px;" :src="form.img" />
        <div style="margin-left:16px;display:flex;flex-direction:column;justify-content:center;">
          <div style="font-weight:600;font-size:20px;margin-bottom:7px;">{{ form.name }}</div>
          <div v-if="form.type.includes('audio')" style="font-size:14px;font-weight:400;">{{ form.size }} · {{ form.type }}</div>
          <div v-else style="font-size:14px;font-weight:400;">{{ form.dimentions }} · {{ form.size }} · {{ form.type }}</div>
        </div>
      </div>

    </div>

    <div style="position:relative;">

      <div style="display:flex;flex-direction:column;">
        <div style="margin-bottom:8px;font-size:12px;font-weight:500;text-transform:uppercase;">Folder</div>
        <div @click.prevent="toggleSelect" style="display:flex;justify-content:space-between;border-radius:8px;padding: 0px 10px;background-color:#f2f3f5;height:40px;">
          <input v-model="folderName" style="font-weight:500;padding:10px;font-size: 16px;background-color:#f2f3f5;width:100%;border:none;" type="text" />
          <div style="display:flex;justify-content:center;align-items:center;">
            <svg height="20" width="20" viewBox="0 0 20 20" focusable="false">
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
        </div>
      </div>

      <div v-if="selectOpen" v-click-away="toggleSelect" style="border: 1px solid rgb(185, 187, 190);max-height:300px;overflow-y: auto;position:absolute;width:100%;background-color:#fff;box-shadow: rgba(79, 84, 92, 0.3) 0px 1px 5px 0px;">
        <div v-for="folder in folders" :key="folder._id" @click.prevent="selectFolder(folder)" class="folder-item" style="padding: 8px 12px;width:100%;cursor:pointer;">{{ folder.name }}</div>
      </div>

    </div>

    <!-- <div style="display:flex;flex-direction:column;margin-top:20px;">
      <div style="margin-bottom:8px;font-size:12px;font-weight:500;text-transform:uppercase;">Comment</div>
        <textarea v-model="form.comment" style="resize:vertical;border-radius:8px;min-height:100px;max-height:200px;padding:10px;font-size: 17px;background-color:#f2f3f5;width:100%;border:none;" type="text" />
    </div> -->

    <div style="padding:40px 0 0 0;display:flex;justify-content:flex-end;">
      <div @click.prevent="closeUpload" style="color:#747f8d;cursor:pointer;font-size:14px;margin-right:20px;background-color:#fff;display:flex;justify-content:center;align-items:center;font-weight:500;padding:0 15px;">
        <div>Cancel</div>
      </div>

      <div @click.prevent="upload" style="cursor:pointer;font-size:14px;color: rgb(6, 6, 7);background-color:#f2f3f5;height:38px;display:flex;justify-content:center;padding:0 30px;font-weight:500;align-items:center;border-radius:3px;">
        <div>Upload</div>
      </div>

    </div>


  </div>

</div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    data: {
      type: Event,
      required: true
    }
  },
  data(){
    return {
      uploadClicked: false,
      form: {
        img: null,
        name: null,
        dimentions: null,
        size: null,
        type: null,
        folder: null
      },
      selectOpen: false,
      // addDetails: false,
      file: null,
      folderName: 'Select a folder to upload'
    }
  },
  computed: {
      ...mapState ({
        folders: state => state.folders.items
     }),
   },
   created(){
     this.uploadFile(this.data.target)
   },
    methods: {
      closeUpload(){
        this.$emit('closeUpload')
      },
      toggleSelect(){
        this.selectOpen = !this.selectOpen
      },
      selectFolder(folder){
        this.toggleSelect()
        this.folderName = folder.name
        this.form.folder = folder._id
      },
      uploadFile(input){
        // var input = event.target;

        if (input.files && input.files[0].size > 2000000000) {
            this.$toast("File too large " + this.formatBytes(input.files[0].size))
        } else  {
          if (input.files && input.files[0]) {

    					var reader = new FileReader();
    					reader.onload = (e) => {
    							this.form.img = e.target.result;
    					}
    					reader.readAsDataURL(input.files[0]);
    			}
          this.file = input.files[0]
          this.form.name = input.files[0].name
          this.form.size = this.formatBytes(input.files[0].size)
          this.form.type = input.files[0].type

          this.getUploadedFileDimensions(input.files[0]).then(({width, height}) => {
              this.form.dimentions = `${height} by ${width} pixels`
          })
            this.addDetails = true

        }

      },
      getUploadedFileDimensions: file => new Promise((resolve, reject) => {
        try {
            let img = new Image()

            img.onload = () => {
                const width  = img.naturalWidth
                const height = img.naturalHeight

                window.URL.revokeObjectURL(img.src)

                return resolve({width, height})
            }

            img.src = window.URL.createObjectURL(file)
        } catch (exception) {
            return reject(exception)
        }
      }),
      formatBytes(bytes, decimals = 2) {
          if (bytes === 0) return '0 Bytes';

          const k = 1024;
          const dm = decimals < 0 ? 0 : decimals;
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

          const i = Math.floor(Math.log(bytes) / Math.log(k));

          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },
      upload(){

        const formData = new FormData()
        formData.append("file", this.file)
        formData.append("folder", this.form.folder)

        if(this.uploadClicked){
          console.log('user spamming')
        } else {
          this.uploadClicked = true
          this.$store.dispatch('folders/upload', formData)
          .then(() => {
            this.closeUpload()
            this.$toast('Uploaded')
            this.uploadClicked = false
            })
          .catch((err) => {
            this.$toast(err)
            this.uploadClicked = false
          })
        }

      }

    }
}
</script>

<style scoped>
textarea {
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: vertical;
    max-height: 200px;
    min-height: 50px;
}

.folder-item:hover {
  background-color: #f2f3f5;
}
</style>
