<template>
  <div class="layerContainer-yqaFcK">
    <div class="backdropWithLayer-3_uhz4" style="opacity: 0.85; background-color: rgb(0, 0, 0); transform: translateZ(0px);"></div>
    <div class="layer-2KE1M9">
        <div class="focusLock-Ns3yie" role="dialog" tabindex="-1">
            <div class="modal-qgFCbT root-1gCeng fullscreenOnMobile-1bD22y" style="opacity: 1; transform: scale(1);">
                <div class="wrapper-2K4Z3k">
                    <div class="imageWrapper-2p5ogY image-1tIMwV" style="width: 772px; height: 773px;">
                      <img alt="" src="https://media.discordapp.net/attachments/767226707768770595/767624005858623498/hfitness-default.jpg" style="width: 772px; height: 773px;">
                    </div>
                      <a class="anchor-3Z-8Bb anchorUnderlineOnHover-2ESHQB downloadLink-1ywL9o" href="https://cdn.discordapp.com/attachments/767226707768770595/767624005858623498/hfitness-default.jpg" rel="noreferrer noopener" target="_blank" role="button" tabindex="0">Open original
                      </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
</style>
