import Vue from 'vue'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

const BASE_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {
    items: [],
    item: {}
  },
  actions: {
    fetchFolders ({ state, commit }) {
      commit('setItems', {resource: 'folders', items: []}, {root: true})

    return axiosInstance.get(`${BASE_URL}/folders`)
      .then(res => {
        const folders = res.data
        commit('setItems', {resource: 'folders', items: folders}, {root: true})
        return state.items
      })
    },
    fetchFolderById ({ state, commit }, folderId){
      commit('setItem', {resource: 'folders', item: {}}, {root: true})
      return axiosInstance.get(`${BASE_URL}/folders/${folderId}`)
      .then(res => {
        const folder = res.data
        commit('setItem', {resource: 'folders', item: folder}, {root: true})
        return state.item
      })
      .catch(err => {
        // console.log(err)
      })
    },
    createFolder({state, commit}, data){
      return axiosInstance.post(`${BASE_URL}/folders/create`, data)
        .then(res => {
          const folder = res.data
          const index = state.items.length
          commit('addItemToArray', {item: folder, index, resource: 'folders'}, {root: true})
          return folder._id
        })
        .catch(err => rejectError(err))
    },
    deleteFile ({commit, state}, fileId) {
      return axiosInstance.delete(`${BASE_URL}/files/delete/${fileId}`)
        .then((res) => {
          const fileId = res.data
          const index = state.item.files.findIndex(data => data._id === fileId)
          if (index > -1) {
            commit('removeFileFromArray', index)
          }
        })
      .catch(err => rejectError(err))
    },
    rename({commit, state}, data){
      return axiosInstance.patch(`${BASE_URL}/folders/rename`, data)
        .then(res => {
          const index = state.items.findIndex(data => data._id === res.data.id)
          commit('updateFolderName', { name: res.data.name, index: index })
          return true
        })
        .catch(err => rejectError(err))
    },
    deleteFolder ({commit, state}, folderId) {
      return axiosInstance.delete(`${BASE_URL}/folders/delete/${folderId}`)
        .then((res) => {
          const folderId = res.data
          const index = state.items.findIndex(data => data._id === folderId)
          if (index > -1) {
            commit('removeFolderFromArray', index)
          }
          return true
        })
      .catch(err => rejectError(err))
    },
    upload ({commit, state}, data) {
      return axiosInstance.post(`${BASE_URL}/files/upload`, data)
      .then(res => {
        if(res.data.folder === state.item._id){
          const index = state.item.files.length
          commit('addFileToFolder', { index: index, file: res.data.file })
          return true
        } else {
          return true
        }
      })
      .catch(err => rejectError(err))
    },



},
  mutations: {
    updateFolderName(state, data) {
      state.item.name = data.name
      state.items[data.index].name = data.name
    },
    removeFileFromArray(state, index){
      state.item.files.splice(index, 1)
    },
    removeFolderFromArray(state, index){
      state.items.splice(index, 1)
    },
    addFileToFolder(state, data) {
      Vue.set(state.item.files, data.index, data.file)
    }
  }
}
