<template>
<div style="background-color:rgba(0, 0, 0, 0.8);position:fixed;top:0;right:0;bottom:0;left:0;z-index:100;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
    <div v-click-away="toggleRename" style="padding:20px;border-radius:5px;width:440px;min-height:258px;background:#fff;display:flex;flex-direction:column;justify-content:center;">

      <div style="margin-bottom:20px;">
        <div style="font-weight:600;text-transform:uppercase;font-size:16px;">Rename {{ folder.name }}</div>
      </div>

        <div style="display:flex;flex-direction:column;">
          <div style="margin-bottom:8px;font-size:12px;font-weight:500;text-transform:uppercase;">New Name</div>
            <input v-model="form.name" :placeholder="folder.name" style="font-weight:500;padding:10px;font-size: 16px;background-color:#f2f3f5;width:100%;border:none;" type="text" />
        </div>

      <div style="padding:40px 0 0 0;display:flex;justify-content:flex-end;">
        <div @click.prevent="toggleRename" style="color:#747f8d;cursor:pointer;font-size:14px;margin-right:20px;background-color:#fff;display:flex;justify-content:center;align-items:center;font-weight:500;padding:0 15px;">
          <div>Cancel</div>
        </div>

        <div @click.prevent="renameFolder" style="cursor:pointer;font-size:14px;color: rgb(6, 6, 7);background-color:#f2f3f5;height:38px;display:flex;justify-content:center;padding:0 30px;font-weight:500;align-items:center;border-radius:3px;">
          <div>Rename</div>
        </div>

      </div>

    </div>

</div>
</template>

<script>
export default {
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      renameClicked: false,
      form: {
        name: null
      }
    }
  },
  methods: {
    toggleRename(){
      this.$emit('toggleRename')
    },
    renameFolder(){
      if(this.renameClicked){
        console.log('user spamming')
      } else {
        this.renameClicked = true
        this.$store.dispatch('folders/rename', { name: this.form.name, folderId: this.folder._id })
        .then((res) => {
          this.toggleRename()
          this.$toast('Folder renamed')
          this.renameClicked = false
        })
        .catch((err) => {
          this.$toast(err)
          this.renameClicked = false
        })
      }
    }
  }
}
</script>
