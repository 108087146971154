import { createWebHistory, createRouter } from 'vue-router';
import store from '@/store'

import Dashboard from '@/pages/Dashboard'
import Folder from '@/pages/Folder'
import Settings from '@/pages/Settings'


import Login from '@/pages/Login'
import Register from '@/pages/Register'
import NotFound from '@/pages/NotFound'



const routes = [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/folder/:id',
      name: 'Folder',
      component: Folder,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/account',
      name: 'Settings',
      component: Settings,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { onlyGuestUser: true }
    },
    // {
    //   path: '/register',
    //   name: 'Register',
    //   component: Register,
    //   meta: { onlyGuestUser: true }
    // },
    {
      path: '/*',
      name: 'NotFound',
      component: NotFound
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

  router.beforeEach((to, from, next) => {
    store.dispatch('auth/getAuthUser')
      .then(() => {
        const isAuthenticated = store.getters['auth/isAuthenticated']

        if (to.meta.onlyAuthUser) {
          if (isAuthenticated) {
            next()
          } else {
            next({name: 'Login'})
          }
        } else if (to.meta.onlyGuestUser) {
          if (isAuthenticated) {
            next({name: 'Dashboard'})
          } else {
            next()
          }
        } else {
          next()
        }
      })
  })

export default router
