import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store  from './store'
import VueClickAway from "vue3-click-away";
import Toasted from "./services/toast.js"
import Clipboard from "./services/clipboard.js"

const app = createApp(App);

app
.use(router)
.use(store)
.use(VueClickAway)
.use(Toasted)
.use(Clipboard)
app.mount('#app')