<template>
  <div style="">
    <nav style="display:flex;justify-content:space-between;padding: 40px;align-items:center;">

    <div style="display:flex;">
      <div style="margin-right:50px;">
        <svg style="height:25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515 215">
            <g>
              <path fill="#2e3338" d="M50.6,71.4C50.6,59.1,61.3,53,71.5,53C85.8,53,93,65.3,95,69.9c0.5,1,2,1.5,2.6,1
                c6.6-2.6,26.6-11.2,33.2-14.3c1-0.5,1.5-2,1-2.6c-3.1-7.7-16.3-38.3-58.2-38.3c-36.8,0-63.9,24.5-63.9,57.2
                c0,63.4,84.8,42.4,84.8,76.6c0,12.8-11.8,18.4-20.9,18.4c-14.3,0-22-12.3-24-16.9c-0.5-1-2-1.5-3.1-1l-32.2,13.8
                c-1,0.5-1.5,2-1,2.6c2.6,7.7,16.3,38.3,58.2,38.3c33.7,0,63.9-22.5,63.9-57.2C135.4,79.1,50.6,103.1,50.6,71.4"/>
              <path fill="#2e3338" d="M172.7,11.1c-13.8,0-24.5,11.2-24.5,24.5s11.2,24.5,24.5,24.5c13.8,0,24.5-11.2,24.5-24.5
                S186.5,11.1,172.7,11.1"/>
              <path fill="#2e3338" d="M413.3,124c2-13.8,13.3-22,26.1-22c15.8,0,24.5,12.3,26.1,22H413.3z M502.7,112.8
                c-2.6-8.7-6.6-16.3-12.3-23c-5.6-6.6-12.3-12.3-20.4-15.8c-8.2-4.1-18.4-5.6-29.6-5.6c-10.2,0-18.9,2-27.6,5.6
                c-8.7,3.6-15.3,8.7-21.5,14.8c-5.6,6.1-10.7,13.8-13.8,22c-3.6,8.2-5.1,17.4-5.1,26.6v0.5c0,10.2,2,19.4,5.1,27.6
                c3.6,8.2,8.7,15.3,14.8,21.5c6.6,6.1,13.8,10.7,23,14.3c8.7,3.6,18.4,5.1,28.6,5.1c12.3,0,23-2,32.2-6.6c8.7-4.1,15.8-9.7,22-16.3
                c1-1,0.5-2,0-3.1l-18.9-18.4c-1-1-2-1-3.1,0c-4.1,3.6-15.3,12.3-29.1,12.3c-15.8,0-27.6-8.2-32.2-20.9h89.4c1,0,2-1,2-2
                c0-2,0.5-4.1,0.5-5.6c0-1.5,0-3.1,0-4.6C506.8,130.7,505.3,121.5,502.7,112.8L502.7,112.8z"/>
              <path fill="#2e3338" d="M193.1,171.5V72.4c0-1-0.5-2-1.5-2h-11.8h-25.5l0,0c-1,0-1.5,1-1.5,2v30.1l0,0v99.1c0,1,0.5,2,1.5,2H166h25.5
                l0,0c1,0,1.5-1,1.5-2V171.5L193.1,171.5z"/>
              <g>
                <path fill="#2e3338" d="M351.5,11.1L314.2,27c-1,0.5-1.5,1-1.5,2v48c0,0-10.2-11.8-34.7-11.8c-32.2,0-68,25.5-68,72.5
                  c0,43.4,31.7,68.5,69,68.5c23.5,0,38.8-12.8,38.8-12.8l0,0v8.7c0,1.5,1,2.6,2.6,2.6h6.6h7.7h3.6h14.3c1.5,0,2.6-1,2.6-2.6v-24
                  v-165C355.1,11.6,353,10.6,351.5,11.1z M311.6,149.1c-5.1,10.7-15.8,18.4-28.1,18.4c-17.4,0-31.2-14.8-31.2-32.7
                  s14.3-32.7,31.2-32.7c12.3,0,23.5,7.7,28.1,18.4V149.1z"/>
              </g>
            </g>
          </svg>

     

      <div style="font-size:12px;font-weight:600;padding:0 0 10px 0;text-transform: uppercase;color:#6a7480;opacity:0.5">CDN</div>
    </div>
      <router-link :to="{ name: 'Dashboard' }">
        <div class="nav-button">Overview</div>
      </router-link>

      <div style="position:relative;">
        <div @click.prevent="toggleFolderMenu" class="nav-button">Folders</div>
            <Folders v-if="folderMenuOpen" @toggleFolderMenu="toggleFolderMenu" @toggleCreateFolder="toggleCreateFolder" @closeFolderMenu="closeFolderMenu"/>
      </div>

      <div @click.prevent="logout">
        <div class="nav-button">Logout</div>
      </div>
   </div>

    
      
     <div style="display:flex;">

          <div class="upload-button" style="">
            <div>Upload</div>
            <input id="file" @change="toggleUpload($event)" style="position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer;" type="file" tabindex="0" multiple="" accept="">
          </div>

          <div v-if="user" style="margin-left:30px;display:flex;align-items:center;">
            <img style="object-fit:cover;height:35px;width:35px;border-radius:50%;" :src="user.image" />
            <div style="margin-left:10px;font-weight:600;color:#4f5660;">{{ user.name }}</div>
          </div>

      </div>


    </nav>
    <CreateFolder v-if="createFolderOpen" @toggleCreateFolder="toggleCreateFolder"/>
  </div>
</template>

<script>
import Folders from '@/components/Folders.vue'
import CreateFolder from '@/components/CreateFolder.vue'
import { mapState } from 'vuex'
export default {
  data(){
    return {
      logoutClicked: false,
      folderMenuOpen: false,
      createFolderOpen: false
    }
  },
  components: {
    Folders,
    CreateFolder
  },
  computed: {
      ...mapState ({
        user: state => state.auth.user
     }),
   },
  methods: {
    toggleCreateFolder(){
      this.createFolderOpen = !this.createFolderOpen
      this.closeFolderMenu()
    },
    toggleFolderMenu(){
      this.folderMenuOpen = true
    },
    closeFolderMenu(){
      this.folderMenuOpen = false
    },
    toggleUpload(event){
      this.$emit('toggleUpload', event)
    },
    logout(){
      if(this.logoutClicked){
        console.log('user spamming')
      } else {
        this.logoutClicked = true
        this.$store.dispatch('auth/logout')
          .then(() => {
            window.location.href = '/'
            this.logoutClicked = false
          })
      }
    }
  }
}
</script>

<style scoped>
.nav-button {
  background-color: #fff;
  font-size:16px;
  cursor:pointer;
  font-weight:500;
  padding:6px 10px;
  color:#4f5660;
  border-radius:3px;
}

.nav-button:hover {
  background-color: rgba(116, 127, 141, 0.08);
}

.nav-divider {
  background-color:rgba(6, 6, 7, 0.08);
  margin: 10px 10px;
  height: 1px;
}

.upload-button {
  position:relative;
  cursor:pointer;
  font-size:14px;
  color: rgb(6, 6, 7);
  background-color:#f2f3f5;
  height:38px;
  display:flex;
  justify-content:center;
  padding:0 15px;
  font-weight:500;
  align-items:center;
  border-radius:3px;
  transition: all 0.3s ease-in-out;
}

.upload-button:hover {
  background-color:rgba(242, 243, 245, 0.4);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
</style>
