<template>
<div style="display:flex;height: calc(100vh - 220px);">
    <div v-if="loaded" style="width:100%;padding: 60px 40px 60px 40px;">

      <div style="display:flex;margin-bottom:20px;align-items:center;">
        <div style="text-transform:uppercase;font-size:16px;font-weight:600;max-width: 450px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ folder.name }}</div>

        <div style="margin-left:20px;font-size:16px;font-weight:600;max-width: 450px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ folder.files.length }} files</div>
        <div @click.prevent="showOptions($event, { file: folder, type: 'folder'})" class="folder-options" style="cursor:pointer;height:22px;margin-left:10px;">

          <svg width="24" height="24" viewBox="0 0 24 24" style="transform: rotate(90deg);">
            <g fill="none" fill-rule="evenodd">
              <path d="M24 0v24H0V0z"></path>
              <path fill="currentColor" d="M12 16c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2-2-.8954305-2-2 .8954305-2 2-2zm0-6c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2-2-.8954305-2-2 .8954305-2 2-2zm0-6c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2-2-.8954305-2-2 .8954305-2 2-2z"></path>
          </g>
        </svg>

        </div>
      </div>


      <div style="display:flex;box-shadow: 0 1px 0 0 rgba(79, 84, 92, 0.04);padding-bottom: 16px;">

        <div style="margin-right: 10px;color:#4f5660;font-size:14px;font-weight:400;">Thumb</div>
        <div style="min-width: 410px;max-width: 410px;margin-left: 17px;margin-right: 10px;color:#4f5660;font-size:14px;font-weight:400;">Name/Size</div>
        <div style="flex:1;margin-left: 4px;color:#4f5660;font-size:14px;font-weight:400;">Uploaded</div>

      </div>

      <!-- file container-->
      <div style="overflow-y: auto;height: 100%;scrollbar-color: #f2f3f5 transparent;">

      <div v-for="file in folder.files" :key="file._id" class="file-row" style="box-shadow: 0 1px 0 0 rgba(79, 84, 92, 0.04);padding: 16px 0;display:flex;align-items:center;">

        <div style="min-width: 40px;max-width: 40px;">
           <div v-if="file.type.includes('audio')" style="margin-left: 5px;">
                <svg height="25" width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                		<path fill="#f2f3f5" d="M501.969,5.638c-5.046-4.379-11.749-6.349-18.366-5.407L160.83,46.341c-11.359,1.623-19.795,11.351-19.795,22.825v92.221
                			v178.088c-13.656-7.956-29.509-12.537-46.421-12.537c-51.021,0-92.531,41.51-92.531,92.531S43.592,512,94.613,512
                			s92.531-41.51,92.531-92.531V181.383l276.663-39.523v151.504c-13.656-7.956-29.509-12.537-46.421-12.537
                			c-51.021,0-92.531,41.51-92.531,92.531c0,51.021,41.51,92.531,92.531,92.531s92.531-41.51,92.531-92.531V115.276V23.055
                			C509.918,16.371,507.018,10.017,501.969,5.638z"/>
                </svg>
           </div>
          <img v-else style="height:40px;width:40px;object-fit:cover;" :src="file.url"/>
        </div>

        <div style="margin-left: 29px;min-width: 410px;max-width: 410px;margin-right: 10px;">
          <div style="display:flex;flex-direction:column;">
            <div style="color:#4f545c;font-weight:500;font-size:14px;text-overflow: ellipsis;overflow: hidden;">{{ file.name }}</div>
            <div style="color:rgb(190, 191, 192);font-size:14px;">{{ file.size | size }}</div>
          </div>


        </div>

        <div style="margin-right:50px;">
          <div style="color:#4f545c;font-weight:500;font-size:14px;">{{ file.createdAt | formatDate }}</div>
        </div>

        <div @click.prevent="showOptions($event, {file: file, type: 'file'})" style="flex: 0 1 auto;width:24px;margin-right: 30px;">
          <div class="file-opts" style="">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path d="M24 0v24H0V0z"></path>
                <path fill="currentColor" d="M12 16c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2-2-.8954305-2-2 .8954305-2 2-2zm0-6c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2-2-.8954305-2-2 .8954305-2 2-2zm0-6c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2-2-.8954305-2-2 .8954305-2 2-2z"></path>
            </g>
          </svg>
          </div>

        </div>

      </div>

    </div>



    </div>
        </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  data(){
    return {
      loaded: false,
      fileData: {
        x: null,
        y: null,
        type: null,
        file: null
      }
    }
  },
  computed: {
      ...mapState ({
        folder: state => state.folders.item
     }),
   },
  created(){
    document.title = 'Side CDN'
    const id = this.$route.params.id
    this.$store.dispatch('folders/fetchFolderById', id)
    .then(() => {
      if(this.folder.name === undefined) {
        this.$toast('Too many requests')
      }
      document.title = `${this.folder.name} - Side CDN`
      this.loaded = true
    })
  },
  methods: {
    toggleDelete(){
      this.$emit('toggleDelete')
    },
    toggleOptions(){
      this.$emit('toggleOptions', this.fileData)
    },
    showOptions(event, data) {
      this.fileData.x = event.clientX
      this.fileData.y = event.clientY
      this.fileData.file = data.file
      this.fileData.type = data.type
      this.toggleOptions()
    },
  }
}
</script>

<style scoped>

.file-opts {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s ease;
}

.file-row:hover .file-opts {
  opacity: 0.5;
}

.file-opts:hover {
  opacity: 1!important;
}

.folder-options {
  opacity:0.5;
  transition: opacity 0.3s ease;
}

.folder-options:hover {
  opacity: 1
}
</style>
