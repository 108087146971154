<template>
  <div style="background-color:rgba(0, 0, 0, 0.8);position:fixed;top:0;right:0;bottom:0;left:0;z-index:100;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
      <div v-click-away="toggleDelete" style="padding:20px;border-radius:5px;width:440px;min-height:258px;background:#fff;display:flex;flex-direction:column;justify-content:center;">

        <div style="margin-bottom:20px;">
          <div style="font-weight:600;text-transform:uppercase;font-size:16px;">Delete '{{ data.data.name }}'</div>
        </div>

        <div v-if="data.type === 'file'" style="margin-bottom:20px;">
          <div style="font-weight:400;font-size:16px;">Are you sure you want to delete <span style="font-weight:600">{{ data.data.name }}</span>? This cannot be undone.</div>
        </div>

        <div v-if="data.type === 'folder'" style="margin-bottom:20px;">
          <div style="font-weight:400;font-size:16px;">Are you sure you want to delete <span style="font-weight:600">{{ data.data.name }}</span>? All files in this folder will also be deleted, This cannot be undone.</div>
        </div>

        <div style="padding:40px 0 0 0;display:flex;justify-content:flex-end;">
          <div @click.prevent="toggleDelete" style="color:#747f8d;cursor:pointer;font-size:14px;margin-right:20px;background-color:#fff;display:flex;justify-content:center;align-items:center;font-weight:500;padding:0 15px;">
            <div>Cancel</div>
          </div>

          <div @click.prevent="deleteData" style="cursor:pointer;font-size:14px;color:#fff;background-color:#FF416C;height:38px;display:flex;justify-content:center;padding:0 30px;font-weight:500;align-items:center;border-radius:3px;">
            <div>Delete</div>
          </div>

        </div>

      </div>

  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      deleteClicked: false
    }
  },
  methods: {
    toggleDelete() {
      this.$emit('toggleDelete')
    },
    deleteData(){
      if(this.data.type === 'file'){
        if(this.deleteClicked){
          console.log('user spamming')
        } else {
          this.deleteClicked = true
          this.$store.dispatch('folders/deleteFile', this.data.data._id)
          .then(() => {
            this.toggleDelete()
            this.$toast('File Deleted')
            this.deleteClicked = false
          })
          .catch((err) => {
            this.$toast(err)
            this.deleteClicked = false
          })
        }
      } else if(this.data.type === 'folder'){
        if(this.deleteClicked){
          console.log('user spamming')
        } else {
          this.deleteClicked = true
          this.$store.dispatch('folders/deleteFolder', this.data.data._id)
          .then(() => {
            this.$router.push({ name: 'Dashboard'})
            this.toggleDelete()
            this.$toast('Folder Deleted')
            this.deleteClicked = false
          })
          .catch((err) => {
            this.$toast(err)
            this.deleteClicked = false
          })
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
