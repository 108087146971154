import axios from 'axios'

const axiosInstance = axios.create({
  timeout: 50000,
  maxContentLength: 52428800,
  maxBodyLength: 52428800
})

axiosInstance.interceptors.request.use(function(config) {
  const token = localStorage.getItem('cdn-jwt') || ''
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, function (err) {
  return Promise.reject(err)
})

export default axiosInstance
